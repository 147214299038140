body {
  background-color: rgb(18,18,18);
  color: white;
}

html, body {
  height: 100%;
  background-color: rgb(18,18,18);
}

.navbar, .navbar-menu, .navbar-start, .navbar-end {
  display: flex !important;
  background-color: rgb(18,18,18) !important;
}

/* -------------------------------------------------------------------------- */

/* Size of the container */
.container.main-content {
  max-width: 800px !important;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

/* Profile image */
.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  float: left;
  
}
.profile-name {
  font-size: 1.6rem;
}

/* Profile description */
.profile-description {
  text-align: left;
  margin-top: 15px;
  margin-left: 20px;
}

.profile-container {
  display: flex; 
  align-items: center;
  gap: 20px;
}

.name-and-resume {
  display: flex;
  flex-direction: column;
  align-items: start; 
  gap: 10px;  
}

.download-resume {
  margin-top: -10px;
  margin-left: 20px;
  color: #485FC6;
}
.download-resume:hover {
  text-decoration: underline;
  color: #485FC6; /* Keep the text color as #485FC6 on hover */
}

/* -------------------------------------------------------------------------- */

/* Line separator */
.separator {
  border-top: .5px solid rgb(78, 77, 77);
  margin-top: 35px; /* Adjust the margin as needed */
  margin-bottom: 30px;;
}
.separator2 {
  border-top: .5px solid rgb(78, 77, 77);
  margin-top: 40px; /* Adjust the margin as needed */
  margin-bottom: 40px;
}


/* -------------------------------------------------------------------------- */

.box {
  margin-top: 20px;
   background-color: transparent;  /* Make the box background transparent */
  border: 1px solid white;  /* Add a white outline/border */
  color: white;  /* Set text color to white */
}

/* Address populated area */
.column.is-four-fifths {
  
  flex: none; /* This disables the default flex behavior */
}
/* Transaction Panel */
.panel {
  margin-top: .5em;
  background-color: white;
}

.panel-heading {
  font-size: 0.8rem; /* Adjust to desired font size */
  background-color: rgb(238, 237, 237);
  
  
}
.panel-block {
    height: 35px; /* Adjust this value as needed */
    overflow: auto;
    display: flex;
    align-items: center; /* To center the text vertically */
    overflow: auto;  /* In case content overflows the set height */
}

.input.is-medium {
  font-size: 0.8rem;
}
.hash--input {
  font-size: 0.8rem;
}

.address-box {
  padding: 1.2em;
}

.box .title {
  color: #333;
}

.faucet-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold; 
}

/* Faucet Logo */
.title {
  color: rgb(168, 67, 67);
  margin-bottom: 1 rem;
}

.faucet-hero-body {
  margin-top: 60px;
}

.withdraw-error {
  color: rgb(209, 16, 16);
  padding: 10px 15px; /* Adjust the padding values as per your needs */
  border-radius: 5px; /* Optional: to give rounded corners */
  display: inline-block;
  margin: 0 auto;
}
.error-message {
  white-space: pre-line;  /* This will allow wrapping */
  word-wrap: break-word; /* This will break long words to the next line */
  overflow-wrap: break-word; /* Similar to word-wrap */
  max-width: 100%; /* Make sure it takes up a maximum of 100% width */
}
/* -------------------------------------------------------------------------- */
/* Faucet Sucess */
.withdraw-success {
  color: green;
  font-size: 1.3rem;
}

.faucet-description {
  margin-top: 5px;
}

.countdown {
  font-size: .8em;
  margin-top: 00px;
  color: rgb(238, 160, 15);
}

.hero {
  margin-bottom: 100px;  /* Adjust this value to increase or decrease the space */
}

/* ----------------Image and Resume Animation---------------------------------------------------------- */
/* Initial states for the profile name and image */
.profile-name,
.profile-image {
  transform: translateX(-100%);
  opacity: 0;
  animation: slideInFromLeftFade 0.5s forwards;
}

/* Staggered animation delays */
.profile-image {
  animation-delay: 0.6s;
  /* slightly after the social links */
}

.profile-name {
  animation-delay: 0.9s;
  /* even slightly after the image */
}

@keyframes slideInFromLeftFade {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Initial state for the "Download Resume" link */
.download-resume {
  transform: translateX(-100%);
  opacity: 0;
  animation: slideInFromLeftFade 0.5s forwards;
  animation-delay: 1.2s;
  /* even slightly after the profile name */
}

/* ----------------SCROLL---------------------------------------------------------- */
.scroll-animated {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.3s ease, transform 0.1s ease;
}

.scroll-animated.animate {
  opacity: 1;
  transform: translateY(0);
}

.scroll-animated-element {
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  opacity: 0;
}


/* ----------------Profile Description Animation---------------------------------------------------------- */

.profile-description {
  text-align: left;
  margin-top: 15px;
  margin-left: 20px;
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInFromLeftFade 1.5s forwards;
  animation-delay: 1.0s;
}

/* ----------------List Hovering --------------------------------------------------------- */

.skills-list .list-item:hover {
  box-shadow: 10px 0px 10px rgba(135, 206, 250, 0.8), -10px 0px 10px rgba(135, 206, 250, 0.8);
  padding-left: 5px;
  padding-right: 5px;

  border-radius: 10px;
}

.skills-list li {
  margin-bottom: 12px;
  opacity: 0;
  transform: translateX(-50%) scale(0.9);
  filter: blur(4px);
  animation: slideInBlurFocus 1.5s forwards;
}
.skills-list .list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.skills-list .list-item span {
  flex-grow: 1;
  /* Puts to right... */
}
.skills-list .list-item img {
  flex-shrink: 0;
  /* Prevents the image from shrinking */
  margin-left: 10px;
  /* Adds space between the text and the image */
}

.skills-list li:nth-child(1) {
  animation-delay: 1.5s;
  margin-left: 0;
}

.skills-list li:nth-child(2) {
  animation-delay: 2s;
    margin-left: 40px;
}

.skills-list li:nth-child(3) {
  animation-delay: 2.5s;
  margin-left: 80px;
}


.skills-list li:nth-child(4) {
  animation-delay: 3s;
  margin-left: 120px;
}
.skills-list li:nth-child(5) {
  animation-delay: 3.5s;
  margin-left: 160px;
}

/* Animation to slide in from left and fade in */
@keyframes slideInBlurFocus {
  from {
    opacity: 0;
    transform: translateX(-50%) scale(0.9);
    filter: blur(4px);
  }

  to {
    opacity: 1;
    transform: translateX(0) scale(1);
    filter: blur(0);
  }
}
/* ----------------Lines---------------------------------------------------------- */

/* Styles for the lines */
.skills-list .list-item::after {
  content: "";
  position: absolute;
  left: calc(100% - 520px);
  /* Starts at the end of the text. You might need to adjust this */
  right: 40px;
  /* Stops before the image; again, you might need to adjust this */
  bottom: 50%;
  height: 1px;
  background-color: white;
  opacity: 0.1;
  /* 40% opacity */
}

/* New styles for hover effect */
.skills-list .list-item:hover::after {
  opacity: .2;
  /* Full opacity on hover */
}
/* Individual adjustments for each line */
.skills-list li:nth-child(1)::after {
  left: calc(100% - 580px);
}

.skills-list li:nth-child(2)::after {
  left: calc(100% - 505px);
}

.skills-list li:nth-child(3)::after {
  left: calc(100% - 430px);
}

.skills-list li:nth-child(4)::after {
  left: calc(100% - 475px);
}

.skills-list li:nth-child(5)::after {
  left: calc(100% - 435px);
}

/* New styles for hover effect */
.skills-list .list-item:hover::after {
  opacity: 1;
  /* Full opacity on hover */
}

/* ---------------- Project Word ---------------------------------------------------------- */
.project-title-fade-in {
  opacity: 0;
  animation: fadeIn 1.5s forwards;
  animation-delay: 4.5s; /* Delay after the skills list */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutToLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.project-title {
  text-align: left;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.2rem;
  color: #485FC6; 
}

.more-projects {
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.down-arrow img {
  opacity: 0;
  transform: translateY(-20px);
  animation: slideArrowDownFade 1.5s forwards;
  animation-delay: 5.0s;
  width: 15px;
  height: auto;
}

/* Keyframe for arrow animation */
@keyframes slideArrowDownFade {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/* ---------------- Audit Table ---------------------------------------------------------- */

.table-container table {
  width: 80%;  /* Reduce width */
  margin: 20px auto;  /* Center the table */
}

.table-container th, 
.table-container td {
  padding: 4px 8px;  /* Adjust padding */
  font-size: 0.9em;  /* Reduce font size */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  border: 1px solid rgb(255, 255, 255);
  text-align: center;
  padding: 8px;
    color: rgb(164, 156, 156) !important;
    
  
}

.flex-container {
  text-align: center;
    margin-top: 20px;
      margin-bottom: 20px;
}

/* ----------------Hyperlinks ---------------------------------------------------------- */

a {
  color: #35afec;
}

a:hover {
  color: #ff4500;
  /* Color when hovered, for example */
}

/* ----------------Owls Youtube link hover grow ---------------------------------------------------------- */

.icon-grow:hover {
  transform: scale(1.2); /* You can adjust the scale factor */
  transition: transform 0.3s ease-in-out;
}


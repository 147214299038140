/* Add more margin to paragraphs */

/* Add more margin to the button */
.button {
    margin-top: .41rem;
    margin-bottom: 1rem;
}

.bold-text {
    font-weight: bold;
}

.smaller-text {
    font-size: 0.9em; /* The address size */
}

.custom-padding {
    padding-left: 15px;  /* Adjust as needed */
    padding-right: 15px; /* Adjust as needed */
}

.address-box.custom-padding {
    position: relative;
    background-color: transparent;
}

/* The eth.png image with opacity */
.address-box.custom-padding::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/eth.png');
    background-repeat: no-repeat;
    background-position: center; 
    background-size: 70%; /* Adjust the size of the image */
    opacity: 0.35; /* Adjust the opacity here */
    z-index: -1;
}

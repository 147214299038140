.leetcode-button img {
    width: 50px;
    height: auto;
    cursor: pointer;
    border: none;
    transition: opacity 0.3s ease;
}

.leetcode-button img:hover {
    opacity: 0.8;
    /* Slight fade effect on hover */
}

.leetcode-button:hover img {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
    /* This scales the image up by 20% on hover */
}
.social-links {
    /* position: absolute; This is commented out. Uncomment if needed. */
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    gap: 10px;

  }
  
  .social-links a {
    text-decoration: none;
        opacity: 0;
    transform: translateX(-100%);
      text-decoration: none;
        animation: slideInFromLeft 0.5s forwards;
  }
  .social-links svg {
    font-size: 1.5rem;
    width: auto;
}
@media (max-width: 768px) {
  .social-links svg {
      font-size: 24px; /* Or whatever minimum size you find appropriate */
  }
}
  
  .social-links a.linkedin {
    color: #0a66c2 !important; /* LinkedIn Blue */
  }
  
  .social-links a.github {
    color: #333 !important; /* GitHub Black */
  }
  
  .social-links a.twitter {
    color: #1da1f2 !important; /* Twitter Blue */
  }
  
  .social-links a.medium {
    color: #00ab6c !important; /* Medium Green */
  }
  
  .social-links a.youtube {
    color: #ff0000 !important; /* YouTube Red */
    padding-right: 25px;
    
  }
  
    @keyframes slideInFromLeft {
      from {
        transform: translateX(-100%);
        opacity: 0;
      }
  
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

        /* Staggered animation delays */
        .social-links a:nth-child(1) {
          animation-delay: 0.3s;
        }
    
        .social-links a:nth-child(2) {
          animation-delay: 0.6s;
        }
    
        .social-links a:nth-child(3) {
          animation-delay: 0.9s;
        }
    
        .social-links a:nth-child(4) {
          animation-delay: 1.2s;
        }
    
        .social-links a:nth-child(5) {
          animation-delay: 1.5s;
        }
    .social-links a:hover svg {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}
.navbar {
    background-color: rgb(18, 18, 18);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 30px;
    /* Adjust padding as needed */
    position: sticky;
    top: 0;
    /* This ensures the navbar sticks to the top of the viewport */
    z-index: 1000;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    /* This makes sure the navbar-end takes up as much space as possible */
}

/* Wallet Connect Button */
.connect-wallet {
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 5px;
    margin-right: 30px;
    margin-top: 20px;
    background-color: transparent !important;
    border: .5px solid white !important;
    box-shadow: none;
    align-self: flex-end;
    opacity: 0;
    transform: translateX(100%);
    animation: slideInFromRight 1.5s forwards;
    animation-delay: 1.0s;
}

.connect-wallet span {
    color: white;
}

.connect-wallet:hover {
    background-color: #e1e1e1 !important;
}

.button.connect-wallet:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* Slight white background on hover for effect */
    color: blue;
    /* or any color you'd like for hover */
}

/* Wallet Animation */
@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Price Feeds */
.price-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;
    font-size: 15px;
    margin-top: 15px;
    animation: moveStockTicker 10s linear infinite;
}

.italic-text {
    font-style: italic;
    margin-left: 35px;
    white-space: nowrap;

}

.currency-price {
    margin: 0 10px;
}

.currency-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes moveStockTicker {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-70%);
    }
}
.modal {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100vw;
    height: 90vh;
    /* background: rgba(0, 0, 0, 0.7); */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    
}

pre {
    background: darkgray;
    padding: 20px;
    border: 1px solid white; 
}


.code-container {
    position: relative;
}

.copy-button {
    position: absolute;
    top: 10px;
    right: 210px;
    border: none;
    cursor: pointer;
}

.clipboard-notification {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
}